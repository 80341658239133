<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";

import {mapActions, mapGetters} from "vuex";
import draggable from "vuedraggable";
import store from "@/state/store";
import {get_acsrf_token} from "@/methods";
import {HalfCircleSpinner} from "epic-spinners";


export default {
  page: {
    title: "Preferences",
    meta: [{ name: "description", content: appConfig.description }]
  },
	computed: {
		...mapGetters({
			getServers: 'getServers',
			getServer: 'getServer',
			getSettings: 'getSettings'
		}),
		...mapActions([
			'setSettingsChild'
		]),
		availableServerIds() {
			let servers = [];
			this.getServers().forEach((server) => {
				servers.push(server.id);
			});
			return servers;
		},
		serverMap() {
			let servers = {};
			this.getServers().forEach((server) => {
				servers[server.id] = server;
			});
			return servers;
		}
	},
  components: {
    Layout,
    ServerNav: () => import('@/components/widgets/ServerNav'),
	  draggable,
	  HalfCircleSpinner
  },
  methods: {
	  async onOrderChanged() {
			if(this.$refs.serverNav) this.$refs.serverNav.forceServerOrder(this.order);
	  },
	  async updatePreferences() {
			this.updatingPreferences = true;
		  await new Promise(resolve => setTimeout(resolve, 1000));
		  try {
			  let acsrf_token = await get_acsrf_token();
			  let payload = {
				  acsrf_token: acsrf_token,
					order: this.order,
			  };

			  let url = new URL(process.env.VUE_APP_ROOT_API + `v1/account/preferences/server-nav`);
			  let response = await fetch(url, {
				  method: 'POST',
				  body: JSON.stringify(payload),
				  credentials: 'include'
			  });

			  if(response.ok) {
				  this.$swal({
					  icon: 'success',
					  text: this.$t('preferences.servernav.save.success')
				  });
				  this.$store.commit('setSettingsChild', {child: 'servers', values: this.order});
			  } else {
				  if (response.status === 429) this.$toast.warning(this.$t('error.server.ratelimit.title'));
				  throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
			  }

		  }  catch (error) {
			  console.log(`[ERROR] ${error}`);
			  this.$swal({
				  icon: 'error',
				  text: this.$t('preferences.servernav.save.error')
			  });
		  }
		  this.updatingPreferences = false;
	  }
  },
  created() {
		this.order = this.getSettings().servers;
		let order = [];

		let knownServers = [];
		if(this.order && this.order.length) {
			this.order.forEach((item) => {
				knownServers.push(item.id);
				/*
				if(!this.availableServerIds.includes(item.id)) {
					console.log('spliced', item);
					this.order.splice(this.order.indexOf(item), 1);
				}
				*/
				if(this.availableServerIds.includes(item.id)) {
					order.push({id: item.id});
				}
			});
		}

		this.getServers().forEach((server) => {
			if(!knownServers.includes(server.id)) {
				order.push({id: server.id});
			}
		});

		this.$nextTick(() => {
			this.order = order;
			this.ready = true;
		})
  },
	mounted() {

	},
	watch: {
		'order': function() {
			if(this.ready) this.onOrderChanged();
		}
	},
	data() {
    return {
			ready: false,
	    updatingPreferences: false,
	    order: [],
	    categories: []
    }
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <h4 class="mb-0 font-size-18">
	      {{ $t('preferences.servernav.title') }}
	      <div class="small text-muted">
		      {{ $t('preferences.servernav.description') }}
	      </div>
      </h4>
      <div class="page-title-right bv-d-xs-down-none">
        <ol class="breadcrumb m-0">
        <li class="breadcrumb-item">
          <router-link to="/">
            CFTools Cloud
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <span aria-current="location">
            Preferences
          </span>
        </li>
      </ol>
      </div>
    </div>
	  <div class="row">
		  <div class="col">
			  <div>
				  <div class="row">
					  <div class="col-9">
						  <div class="w-50 ml-auto mr-auto" v-if="ready">
							  <draggable draggable=".draggable" v-model="order" group="people" @start="drag=true" @end="drag=false">
								  <div
										  v-for="server in order"
										  :key="server.identifier"
										  class="card card-body hard-border draggable"
										  style="cursor: pointer;"
								  >
									  <i class="fas fa-sort float-left mt-2 position-absolute"/>
									  <div class="row ml-4">
										  <div class="col">
											  <h3 class="mb-0 d-inline">
												  <template v-if="server.id in serverMap">
													  {{ serverMap[server.id].identifier }}
												  </template>
												  <template v-else>
													  {{ server.id }}
												  </template>
											  </h3>
										  </div>
									  </div>
								  </div>
							  </draggable>
						  </div>
					  </div>
					  <div class="col-3 card card-body">
						  <button class="btn btn-success btn-block" v-on:click="updatePreferences" :class="{'disabled': updatingPreferences}">
							  <half-circle-spinner
									  v-if="updatingPreferences"
									  :animation-duration="1200"
									  :size="16"
									  class="align-middle d-inline-block text-black"
							  />

							  <span class="text-black" v-if="!updatingPreferences">
								  <i class="far fa-save"/>
								  {{ $t('preferences.servernav.save.button') }}
							  </span>
						  </button>
						  <h5 class="text-uppercase p-0 m-0 mt-3">
							  {{ $t('preferences.servernav.preview.title') }}
						  </h5>
						  <div class="mt-3" v-if="ready">
							  <span class="menu-title">SERVERS</span>
						    <ServerNav ref="serverNav"/>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
  </Layout>
</template>